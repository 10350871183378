<script lang="ts" setup>
import Parallaxy from '@lucien144/vue3-parallaxy'
import Link2 from '@/components/Button/Link2.vue'
import Title1 from '@/components/Title/Title1.vue'
import { type HomeSectionList } from '@/utils/types'
import { usePageStore } from '@/stores'
const pageStore = usePageStore()
const blockData = computed(() => pageStore.homeSection3)

const props = defineProps({
  bgColor: {
    type: String,
    default: ''
  }
})

const lists: Ref<HomeSectionList[]> = ref([
  {
    pic: blockData.value.image_lg,
    text: blockData.value.description,
    link: blockData.value.url
  } as HomeSectionList
])
</script>

<template>
  <section class="section-box">
    <div class="wrap relative">
      <div class="section-number">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: 120 } }" :speed="0" direction="opposite"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <SectionNumber no="3" :class="{ white: props.bgColor === '#9ba8bb' }" />
        </Parallaxy>
      </div>
      <div class="section-title">
        <Title1 class="section-title__inner">
          <template v-slot:en>Sustainability</template>
          <template v-slot:tw>
            永續生活 —— <br />
            更循環、永續的選擇
          </template>
        </Title1>
      </div>
      <div v-for="(item, index) in lists" :key="index" :class="`lists-${index + 1}`">
        <div class="lists__pic">
          <nuxt-link :to="item.link">
            <Nimg :src="item.pic?.link" :alt="item.pic?.alt" :title="item.pic?.title" />
          </nuxt-link>
        </div>
        <div class="lists__text" v-if="item.text">{{ item.text }}</div>
        <Link2 :to="item.link" title="永續生活 立即探索" linkTitle="立即探索" class="circle" v-if="item.link" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section-box {
  @include min-media(991) {
    margin-bottom: toRem(80);
  }

  .wrap {
    @include grid;

    @include max-media(990) {
      max-width: toRem(520);
      @include grid(4, 24);
    }
  }
}

.section-title {
  order: 2;
  grid-column: 8 / span 4;
  margin-top: toRem(150);

  @include max-media(990) {
    grid-column: 1 / span 4;
    margin-top: 0;
    margin-bottom: toRem(60);
  }
}

.section-number {
  @include min-media(991) {
    grid-column: 12 / span 1;
    width: toRem(112);
    height: toRem(126);
    margin-top: toRem(-100);
    order: 3;
  }

  @include max-media(990) {
    order: 1;
    grid-column: 4 / span 1;
    width: 100%;
    height: auto;
    margin-left: auto;
  }
}

.lists {
  &-1 {
    order: 1;
    grid-column: 1 / span 4;

    @include max-media(990) {
      order: 3;
    }
  }

  &__pic {
    margin-bottom: toRem(10);

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    margin-bottom: toRem(12);
  }
}
</style>
